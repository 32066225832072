<template>
  <TheWrapper>
    <h2
      v-if="product"
      class="step__main-title"
    >
      {{ $t('Tell us more about') }}
    </h2>
    <ProductQuantityChooser
      v-if="product"
      v-bind="product"
      :value="selectedQuantity"
      data-test="quantity-selection"
      @update="updateQuantity"
    />

    <div
      ref="reasons"
      class="reasons"
    >
      <p
        v-if="product"
        class="reasons__title"
      >
        {{ $t('For what reason?') }}
      </p>
      <h2 v-else>
        {{ $t('Why do you want to return?') }}
      </h2>

      <ItemSelector
        v-for="reason in reasons"
        :key="reason.id"
        :field-id="`reason-${reason.id}`"
        :value="reason.id"
        :checked="reason.selected"
        :visible-checkmark="false"
        group="reasons"
        data-test="reason"
        @on-change="selectReason"
      >
        {{ reason.description }}
      </ItemSelector>
      <transition name="fade">
        <div
          v-if="showMessageField"
          class="entry-message"
          data-test="message-field"
        >
          <label
            class="entry-message__label"
            for="message"
          >{{ $t('Specify your reason (optional)') }}</label>
          <textarea
            id="message"
            v-model="reasonMessage"
            name="message"
            class="entry-message__field"
            rows="5"
            cols="30"
            data-test="reason-message"
          />
        </div>
      </transition>
    </div>

    <UiButton
      mode="primary"
      class="main-button"
    >
      <button
        class="main-button__btn"
        data-test="main-button"
        :disabled="disableNext"
        :style="brandStyle"
        @click="showNextItem"
      >
        <span v-if="hasNextItem">{{ $t('Next item') }}</span>
        <span v-else-if="completedAllSteps">{{ $t('Go to summary') }}</span>
        <span v-else-if="refundOptions.length > 0">{{ $t('Refund method') }}</span>
        <span v-else>{{ $t('Return method') }}</span>
        <FontAwesomeIcon :icon="$options.faChevronRight" />
      </button>
    </UiButton>
  </TheWrapper>
</template>

<script>
import { brandColourMixin } from '@/components/mixins'
import { mapState } from 'vuex'
import { UiButton } from '@sendcloud/ui-library'
import ItemSelector from '@/components/common/ItemSelector.vue'
import TheWrapper from '@/components/layout/TheWrapper'
import ProductQuantityChooser from '@/components/ProductQuantityChooser.vue'
import {
  APP_UPDATE_RETURNED_ITEM_REASON,
  APP_UPDATE_RETURNED_ITEM_QUANTITY,
  APP_SELECT_REASON_WITHOUT_PRODUCTS,
} from '@/store/action.types'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import {
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons'
import { handleRouteRejection } from '@/utils'
import { OTHER_RETURN_REASON_ID } from '@/constants.js'

export default {
  name: 'ReturnReasons',
  faChevronRight,
  components: {
    ItemSelector,
    FontAwesomeIcon,
    TheWrapper,
    UiButton,
    ProductQuantityChooser,
  },
  mixins: [brandColourMixin],
  beforeRouteUpdate(to, _from, next) {
    this.product = this.findProduct(to.params.item)
    const selected = this.returnedItem || this.detachedReason
    this.reasonMessage = (selected !== undefined && selected.message) || ''

    if (selected && selected.reason) {
      // SC-17419: move the focus to the currently selected reason
      this.$refs.reasons?.querySelector(`#reason-${selected.reason}`).focus()
    }

    next()
  },
  data() {
    return {
      product: undefined,
      // SC-17561, selecting different reasons was wiping out the value typed by the user.
      // by keeping this in the component's state we avoid that.
      reasonMessage: '',
    }
  },
  computed: {
    ...mapState({
      returnedItems: (state) => state.returnedItems,
      returnReasons(state) {
        const allReasons = state.returnReasons
        const otherReason = allReasons.find((x) => x.id === OTHER_RETURN_REASON_ID)
        if (otherReason === undefined) {
          // SC-18612, "Other" is mandatory so we force it to be in the list
          // Note: `.push()` mutates the state, and this is haram. We do further lookups for "other" on this computed
          // prop and putting it at the beggining of the list makes the runtime execution life easier
          return [{ id: OTHER_RETURN_REASON_ID, description: this.$t('Other (explain in message)') }].concat(allReasons)
        }
        return allReasons
      },
      packingItems: (state) => state.packingItems,
      detachedReason: (state) => state.detachedReason,
      completedAllSteps: (state) => state.completedAllSteps,
      refundOptions: (state) => state.refundOptions,
    }),
    returnedItem() {
      if (this.returnedItems.length === 0 || this.product === undefined) return undefined
      return this.returnedItems.find((x) => x.id === this.product.id)
    },
    selectedQuantity() {
      if (!this.returnedItem) return this.product.quantity
      return this.returnedItem.quantity
    },
    hasNextItem() {
      if (!this.product) return false
      const nextIndex = this.returnedItems.findIndex((item) => item.id === this.product.id) + 1
      return nextIndex < this.returnedItems.length
    },
    disableNext() {
      if (!this.product) {
        return this.detachedReason === undefined || !this.detachedReason.reason
      }
      return this.returnedItem === undefined || this.returnedItem.reason === undefined
    },
    reasons() {
      const item = this.returnedItem || this.detachedReason
      const reasons = this.returnReasons.map((reason) => {
        const selected = item ? reason.id === item.reason : false
        return { ...reason, selected }
      })
      const otherReason = reasons.find((item) => item.id === OTHER_RETURN_REASON_ID)
      return [...reasons.filter((x) => x.id !== otherReason.id), otherReason]
    },
    showMessageField() {
      if (this.returnedItems.length === 0 || this.product === undefined) {
        return this.detachedReason && this.detachedReason.reason === OTHER_RETURN_REASON_ID
      }
      return this.returnedItem && this.returnedItem.reason === OTHER_RETURN_REASON_ID
    },

    currentMessage() {
      if (this.product === undefined && this.detachedReason !== undefined) {
        return this.detachedReason.message
      }

      if (this.returnedItem !== undefined && this.returnedItem.message) {
        return this.returnedItem.message
      }

      return ''
    },
  },
  created() {
    this.product = this.findProduct(this.$route.params.item)
    const selected = this.returnedItem || this.detachedReason
    this.reasonMessage = (selected !== undefined && selected.message) || ''
  },
  methods: {
    findProduct(productID) {
      const product = this.packingItems.find((item) => item.id === productID)
      if (product === undefined && this.packingItems.length > 0) return this.packingItems[0]
      return product
    },
    updateQuantity(id, quantity) {
      this.$store.dispatch(APP_UPDATE_RETURNED_ITEM_QUANTITY, { id, quantity })
    },
    async showNextItem() {
      await this.updateMessage(this.showMessageField ? this.reasonMessage : '')

      if (!this.hasNextItem) {
        if (this.completedAllSteps) {
          this.$router.push({ name: 'summary' }).catch(handleRouteRejection)
        } else if (this.refundOptions.length === 0) {
          this.$router.push({ name: 'return-methods' }).catch(handleRouteRejection)
        } else {
          this.$router.push({ name: 'refund-options' }).catch(handleRouteRejection)
        }
        return
      }

      const nextIndex = this.returnedItems.findIndex((x) => x.id === this.returnedItem.id) + 1
      const nextID = this.returnedItems[nextIndex].id
      this.$router.push({ name: this.$route.name, params: { item: nextID } }).catch(handleRouteRejection)
    },
    async selectReason(_, reason) {
      if (!reason) { return }
      const { dispatch } = this.$store
      if (this.product === undefined) {
        return dispatch(APP_SELECT_REASON_WITHOUT_PRODUCTS, { reason })
      }

      await dispatch(APP_UPDATE_RETURNED_ITEM_REASON, {
        id: this.product && this.product.id,
        newReason: reason,
      })
    },

    updateMessage(newMessage) {
      const { dispatch } = this.$store
      if (this.returnedItem) {
        dispatch(APP_UPDATE_RETURNED_ITEM_REASON, {
          id: this.returnedItem.id,
          newReason: this.returnedItem.reason,
          newMessage,
        })
      } else {
        if (this.detachedReason === undefined || !this.detachedReason.reason) return
        dispatch(APP_SELECT_REASON_WITHOUT_PRODUCTS, {
          reason: this.detachedReason.reason,
          newMessage,
        })
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.step__subtitle {
  font-weight: 700;
  margin: 2.125rem auto 0;
  text-align: center;
}
.reasons {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}
.reasons__title {
  margin: 2.125rem auto 0;
  font-weight: 700;
}
.product__name {
  font-weight: 700;
  margin: 0;
}
.product__quantity {
  color: #888888;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.7s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
