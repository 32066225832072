<template>
  <div class="chooser">
    <div class="card">
      <p class="card__name">
        {{ name }}
      </p>
      <p class="card__quantity">
        {{ $t('Quantity: {quantity}', { quantity }) }}
      </p>
    </div>

    <UiNumberStepper
      v-if="quantity > 1"
      class="number-stepper-override"
      :decrease-text="$t('Remove an item')"
      :increase-text="$t('Add an item')"
      :max-value="quantity"
      :min-value="1"
      :value="value"
      :label="$t('How many would you like to return?')"
      name="return_item_quantity"
      type="number"
      @input="update"
    />
  </div>
</template>

<script>
import { UiNumberStepper } from '@sendcloud/ui-library'

export default {
  name: 'ProductQuantityChooser',
  components: {
    UiNumberStepper,
  },

  props: {
    id: { type: String, required: true },
    name: { type: String, required: true },
    quantity: { type: Number, required: true },
    value: { type: Number, required: true },
  },
  methods: {
    update(newValue) {
      this.$emit('update', this.id, newValue)
    },
  },
}
</script>
<style lang="scss" scoped>
.ui-number-stepper {
  text-align: center;
}
.card__name {
  font-weight: 700;
  margin-top: 0;
}
.card__quantity {
  color: rgba(33, 33, 33, 0.6);
}
</style>
